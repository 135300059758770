import React from "react";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import { ToastContainer } from "react-toastify";

import "./styles/main.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

function App() {
	return (
		<React.StrictMode>
			<Router>
				<Navbar />
				<ToastContainer
					position="bottom-right"
					toastStyle={{ backgroundColor: "black", color: "white" }}
				/>

				<Footer />
			</Router>
		</React.StrictMode>
	);
}

export default App;
