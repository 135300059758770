import "../styles/footer.css";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { FaCopyright, FaEnvelope, FaFacebook, FaWhatsapp } from "react-icons/fa";
import fotopd from "./comunepadova.png";
export const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer-container">
				<div className="item1">
					Tutti i diritti riservati! I materiali presenti nel sito
					sono di proprietà della Padova Ultimate Frisbee, per
					richiedere informazioni o materiale potete scriverci una
					mail o contattarci sui nostri social Network.
				</div>

				<div className="item2">
					<div>
						<span style={{ paddingRight: 5 }}>Copyright </span>
						<FaCopyright />{" "}
						<span style={{ paddingLeft: 5 }}>
							{new Date().getFullYear()} Padova Ultimate
							Frisbee. All Rights Reserved.
						</span>
					</div>
				</div>
					<div className="item3">
						<img
							id="comunepadova"
							src={fotopd}
							alt="Con il patrocinio del comune di Padova"
						/>
						<p>Con il patrocinio ed il contributo del comune di Padova</p>
					</div>
				<Link to="/contatti">
					<p>
						<FaEnvelope />
					</p>
				</Link>
				<Link to="/contatti">
					<p>
						<FaWhatsapp />
					</p>
				</Link>
				<Link to="/contatti">
					<p>
						<FaFacebook />
					</p>
				</Link>
			</div>
		</footer>
	);
};

export default Footer;
