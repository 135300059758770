import "../styles/home.css";
import logo from "../photo/home/logo_puf.jpg";
import ultimate from "../photo/home/ultimate.jpg";
import allenamento from "../photo/home/allenamento.jpg";
import { ImageSlider } from "../components/ImageSlider";

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export function Home() {
	return (
		<div>
			<div className="grid-container">
				<div className="grid-item">
					<Link to="/ultimate">
						<img src={ultimate} alt="Ultimate Frisbee" />
						<h2>Cos'è l'Ulltimate</h2>
						<p>
							Spirito del gioco La prima regola dell'Ultimate è lo “Spirito Del
							Gioco”. L'agonismo e la competizione sono parti fondamentali di
							questo sport, così come la voglia di vincere, ma mai a scapito ...
						</p>
						<button>Leggi {">>"}</button>
					</Link>
				</div>
				<div className="grid-item">
					<Link to="/chi_siamo">
						<img src={logo} alt="Logo Padova Ultimate Frisbee" />
						<h2>Chi siamo</h2>
						<p>
							L'Associazione Sportiva Padova Ultimate Frisbee nasce a Padova il
							25 gennaio 2005 per meglio sviluppare la parte delle attività
							dell'Associazione Sportiva e Culturale “Melina” relativa al Giuoco
							...
						</p>
						<button>Leggi {">>"}</button>
					</Link>
				</div>

				<div className="grid-item">
					<Link to="/allenamenti">
						<img src={allenamento} alt="Allenamento" />
						<h2>Allenamenti</h2>
						<p>
							CUS Padova Ultimate Frisbee <br /> Senior (anno di nascita fino al
							2003 compreso) <br />
							Under 20 (anno di nascita dal 2004 in poi)
							<br /> Master (over 33) ... <br />
						</p>
						<button>Leggi {">>"}</button>
					</Link>
				</div>
			</div>
			<ImageSlider />
		</div>
	);
}
