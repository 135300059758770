import React from "react";
import "../styles/contact.css";
import emailjs from "emailjs-com";
import {
	FaEnvelope,
	FaFacebook,
	FaInstagram,
	FaWhatsapp,
} from "react-icons/fa";
import { toast } from "react-toastify";

export const Contact = () => {
	const [formStatus, setFormStatus] = React.useState("Invia");
	const onSubmit = (e: any) => {
		if (
			e.target[0].value === "" ||
			e.target[1].value === "" ||
			e.target[2].value === ""
		) {
			return;
		}
		e.preventDefault();
		setFormStatus("Inviando...");

		emailjs
			.sendForm(
				"service_gvsxf1b",
				"template_r5i50lm",
				e.target,
				"eACY-By7x4h3z4q1e"
			)
			.then(
				(result) => {
					toast.success("Messaggio inviato con successo!", {
						position: "bottom-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				},
				(error) => {
					toast.error(error + "\nProvare a contattare con un'altro metodo", {
						position: "bottom-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
			);

		for (let i: number = 0; i < e.target.length - 1; i++) {
			e.target[i].value = "";
		}
		setTimeout(() => setFormStatus("Invia"), 3000);
	};
	return (
		<div className="contatti">
			<div className="generali">
				<p>
					Manuel: 3494152748
					<FaWhatsapp style={{ color: "green" }} />
				</p>
				<div>
					<a
						href="https://it-it.facebook.com/PadovaUltimateFrisbee/"
						target={"_blank"}
						rel="noreferrer"
					>
						<p>
							PadovaUltimateFrisbee
							<FaFacebook style={{ color: "blue" }} />
						</p>
					</a>
					<p>
						info@ultimatepadova.it <FaEnvelope />
					</p>
					<a
						href="https://www.instagram.com/padova_ultimate_frisbee/"
						target={"__blank"}
						rel="noreferrer"
					>
						<p>
							@padova_ultimate_frisbee{" "}
							<FaInstagram style={{ color: "purple" }} />
						</p>
					</a>
				</div>
			</div>
			<div className="form-div">
				<h1 id="contattaci_title">Contattaci!</h1>
				<form className="cf" onSubmit={onSubmit}>
					<input type="text" placeholder="Nome e Cognome" name="name" />
					<input type="email" placeholder="Email" name="email" />

					<textarea name="message" placeholder="Messaggio"></textarea>
					<input type="submit" value={formStatus} id="input-submit" />
				</form>
			</div>
		</div>
	);
};
