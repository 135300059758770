import "../styles/descriptive_pages.css";
import frisbee from "../photo/coseUltimate/foto_frisbee.jpg";
import volantino from "../media/volantino_puf.pdf";

export const Ultimate = () => {
	return (
		<div>
			<div id="image">
				<img src={frisbee} alt="Foto chi siamo" id="img" />
			</div>
			<div className="content">
				<h1 id="page_title">Cos'è l'Ultimate</h1>
				<h3 className="subtitle">Spirito del gioco</h3>
				<p className="descrizione">
					La prima regola dell'Ultimate è lo “Spirito Del Gioco”. L'agonismo e
					la competizione sono parti fondamentali di questo sport, così come la
					voglia di vincere, ma mai a scapito dell'onestà in campo e del
					rispetto dell'avversario, delle regole e del piaceredi giocare.
				</p>
				<h3 className="subtitle">I fondamentali</h3>
				<p className="descrizione">
					Due squadre, 7 contro 7, un campo rettangolare e due mete agli
					estremi: la squadra che attacca cerca di avanzare attraverso una serie
					continuata di passaggi, senza che il giocatore possa correre quando è
					in possesso del disco. È punto quando un giocatore dell'attacco riceve
					un passaggio al volo dentro l'area di meta avversaria. Non è permesso
					il contatto fisico. L'attacco perde il possesso del disco quando un
					passaggio tra due compagni di squadra, per qualsiasi motivo, non va a
					buon fine. In tal caso cambiano i ruoli “attacco/difesa”.
				</p>
				<h3 className="subtitle">Contatti fisici</h3>
				<p className="descrizione">
					Non sono ammessi contatti fisici. Ogni contatto fisico che ostacoli in
					qualche modo l'azione è considerato un fallo, sono ammessi solo lievi
					contatti fisici involontari (ad esempio durante una presa in salto).
				</p>
				<h3 className="subtitle">Auto-arbitraggio</h3>
				<p className="descrizione">
					Nell'Ultimate l'arbitro non esiste. Ogni infrazione, fallo o disputa è
					segnalata e risolta dai giocatori stessi. Quando un fallo causa la
					perdita del possesso, il gioco ricomincia come se il possesso fosse
					stato mantenuto. Nel caso di una contestazione per la quale non è
					possibile raggiungere un accordo (ad esempio se il giocatore a cui
					viene chiamato il fallo non è d'accordo), il disco torna a chi ne
					aveva il possesso prima dell'evento che l'ha generata, ed il gioco
					riprende. <br />
					<br />
				</p>

				<a href={volantino} download="volantino_PUF" style={{ color: "blue" }}>
					Clicca per scaricare il volantino con il riassunto delle regole!
				</a>
			</div>

			<div className="federazioni">
				<h2>Dai un'occhiata alla federazione Italiana, Europea e Mondiale</h2>
				<a href="https://www.fifd.it/">
					<img
						className="foto_federazioni"
						src="https://www.fifd.it/images/logo-fifd.png"
						alt="Foto Fifd"
					/>
				</a>
				<a href="https://www.ultimatefederation.eu/">
					<img
						className="foto_federazioni"
						src="https://images.squarespace-cdn.com/content/v1/6231e448c73e710fad2df668/825471d6-dfe8-4500-be65-5a8f8c0e35ed/European_Ultimate_Federation_EUF_Logo.png?format=1500w"
						alt="Foto Euf"
					/>
				</a>
				<a href="https://wfdf.sport/">
					<img
						src="https://wfdf.sport/wp-content/uploads/2020/11/WFDF-Logo-360-Header.png"
						alt="Foto Wfdf"
						className="foto_federazioni"
					/>
				</a>
			</div>
		</div>
	);
};
