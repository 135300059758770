import { useRef } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { FaArrowUp, FaBars } from "react-icons/fa";
import "../styles/navbar.css";
import { Home } from "../pages/Home";
import { Contact } from "../pages/Contact";
import { Chi_siamo } from "../pages/Chi_siamo";
import logo from "../photo/padova-ultimate-frisbee-logo.png";
import { Ultimate } from "../pages/Ultimate";
import { Allenamenti } from "../pages/Allenamenti";

type menuStatus = "open_ham" | "nav";

export var nav_status: string = "";

export function Navbar() {
	const navRef = useRef<HTMLElement>(null);

	const showNavbar = (status: menuStatus) => {
		if (status === "open_ham") {
			navRef.current?.classList.toggle("responsive_nav");
		} else if (status === "nav") {
			navRef.current?.classList.toggle("responsive_nav");
		}
		nav_status = status;
	};

	return (
		<div>
			<header>
				<img src={logo} alt="Logo Padova Ultimate Frisbee" />
				<nav ref={navRef}>
					<Link to="/" className="dir" onClick={() => showNavbar("nav")}>
						Home
					</Link>
					<Link
						to="/ultimate"
						className="dir"
						onClick={() => showNavbar("nav")}
					>
						Cos'è l'Ultimate
					</Link>
					<Link
						to="/chi_siamo"
						className="dir"
						onClick={() => showNavbar("nav")}
					>
						Chi siamo
					</Link>
					<Link
						to="/allenamenti"
						className="dir"
						onClick={() => showNavbar("nav")}
					>
						Allenamenti
					</Link>
					<Link to="/#" className="dir" onClick={() => showNavbar("nav")}>
						Progetto scuole
					</Link>
					<Link to="/#" className="dir" onClick={() => showNavbar("nav")}>
						Tornei
					</Link>
					<Link
						to="/contatti"
						className="dir"
						onClick={() => showNavbar("nav")}
					>
						Contattaci
					</Link>
					<button className="nav-btn" onClick={() => showNavbar("nav")}>
						<FaArrowUp />
					</button>
				</nav>
				<button className="nav-btn" onClick={() => showNavbar("open_ham")}>
					<FaBars />
				</button>
			</header>

			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/contatti" element={<Contact />} />
				<Route path="/chi_siamo" element={<Chi_siamo />} />
				<Route path="/ultimate" element={<Ultimate />} />
				<Route path="/allenamenti" element={<Allenamenti />} />
			</Routes>
		</div>
	);
}
