import "../styles/descriptive_pages.css";
import senior from "../media/Stagione2022-2023.pdf";
import junior from "../media/StagioneJunior2022-2023.pdf";
import allenamenti from "../photo/allenamenti/foto_allenamento.jpg";

export const Allenamenti = () => {
	return (
		<div>
			<div id="image">
				<img src={allenamenti} alt="Foto allenamenti" id="img" />
			</div>
			<div className="content">
				<h1 id="page_title">
					Di seguito gli orari degli allenamenti per la stagione 2022/23
				</h1>
				<h3 className="subtitle">Senior maschile:</h3>
				<p className="descrizione">
					Martedì, 19:45-21:45. Via G.Canestrini (San Paolo)
					<br />
					Giovedì, 19:45-21:45. Via J.Corrado 4 (CUS esterno)
				</p>
				<h3 className="subtitle">Senior femminile (Senior + U20):</h3>
				<p className="descrizione">
					Martedì, 19:45-21:45. Via G.Canestrini (San Paolo)
					<br />
					Giovedì, 19:45-21:45. Via J.Corrado 4 (CUS esterno)
				</p>
				<h3 className="subtitle">U20 maschile</h3>
				<p className="descrizione">
					Lunedì, 19:45-21:45. Via J.Corrado 4 (CUS esterno)
					<br />
					Giovedì, 19:45-21:45. Via G.Canestrini (San Paolo)
				</p>
				<h3 className="subtitle">Master</h3>
				<p className="descrizione">
					Lunedì, 19:45-21:45. Via J.Corrado 4 (CUS esterno)
					<br />
					Giovedì, 19:45-21:45. Via G.Canestrini (San Paolo)
				</p>
				<h3 className="subtitle">Allenamenti aperti mixed (per neofite/i)</h3>
				<p className="descrizione">
					Lunedì, 19:45-21:45. Via J.Corrado 4 (CUS esterno)
					<br />
					Giovedì, 19:45-21:45. Via G.Canestrini (San Paolo)
				</p>
				<h3 className="subtitle">Junior U16 (anno di nascita dal 2008)</h3>
				<p className="descrizione">
					Cus grande (Via J.Corrado 4, campo esterno ). Lunedì e mercoledì,
					17.00-18.30 <br />
					Cus piccolo (Via Giordano Bruno 27). Lunedì e mercoledì, 15.00-16.30
					<br />
					Albignasego (Via San Tommaso). Martedì e venerdì, 15.00-16.30 <br />
					Montà (Via Stefanini). Lunedì e mercoledì, 17.30-19.00 <br />
				</p>
				<h3 className="subtitle">Palestra Pesi</h3>
				<p className="descrizione">
					La palestra pesi del Cus Padova sarà disponibile per la sezione
					Ultimate in due fasce serali. Si potrà accedere esclusivamente in
					presenza dell'allenatore di riferimento, quindi orari e giorni
					verranno comunicati dal referente squadra. Per chi volesse accedere in
					altri orari (dalle 10:00 alle 18:00), sarà necessario pagare una quota
					extra di 100€ tramite la segreteria del Cus. Essendo gli spazi
					ristretti e il numero di interessati alto, viene richiesto ad ogni
					atleta di attenersi esclusivamente agli orari comunicati e di prendere
					parte all'allenamento con impegno e solo se presente un reale
					interesse.
				</p>
				<h3 className="subtitle" style={{ margin: "1rem 0" }}>
					Per maggiori informazioni consulta i PDF
				</h3>
				<a href={senior} download="Stagione2022-2023" style={{ color: "blue" }}>
					Allenamenti Senior
				</a>
				<br />
				<a
					href={junior}
					download="StagioneJunior2022-2023"
					style={{ color: "blue" }}
				>
					Allenamenti Junior U16
				</a>
			</div>
		</div>
	);
};
