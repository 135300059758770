import React, { useState } from "react";

import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import "../styles/imageSlider.css";

function importAll(r: any) {
	let images: any = {};
	r.keys().forEach((item: any, index: number) => {
		images[item.replace("./", "")] = r(item);
	});
	return images;
}
const images = importAll(
	require.context("../photo/home/gallery/", false, /\.(png|jpe?g|svg)$/)
);

type SliderDataHolder = {
	image: string;
};

const SliderData: SliderDataHolder[] = [];
const sliderPopulator = () => {
	let keys: string[] = Object.keys(images);
	if (SliderData.length !== keys.length)
		keys.forEach((key) => {
			SliderData.push({ image: images[key] });
		});
};

export const ImageSlider = () => {
	sliderPopulator();

	const slides = SliderData;
	const [current, setCurrent] = useState(0);
	const length = slides.length;

	const nextSlide = () => {
		setCurrent(current === length - 1 ? 0 : current + 1);
	};

	const prevSlide = () => {
		setCurrent(current === 0 ? length - 1 : current - 1);
	};

	if (!Array.isArray(slides) || slides.length <= 0) {
		return null;
	}

	return (
		<section className="slider">
			<FaArrowAltCircleLeft className="left-arrow" onClick={prevSlide} />
			<FaArrowAltCircleRight className="right-arrow" onClick={nextSlide} />
			{SliderData.map((slide, index) => {
				return (
					<div
						className={index === current ? "slide active" : "slide"}
						key={index}
					>
						{index === current && (
							<img src={slide.image} alt="Foto PUF" className="image" />
						)}
					</div>
				);
			})}
		</section>
	);
};
