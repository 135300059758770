import "../styles/descriptive_pages.css";
import chi_siamo from "../photo/chi_siamo/chi_siamo.jpg";
export const Chi_siamo = () => {
	return (
		<div>
			<div id="image">
				<img src={chi_siamo} alt="Foto chi siamo" id="img" />
			</div>
			<div className="content">
				<h1 id="page_title">Chi siamo</h1>
				<p className="descrizione">
					L'Associazione Sportiva Padova Ultimate Frisbee nasce a Padova il 25
					gennaio 2005 per meglio sviluppare la parte delle attività
					dell'Associazione Sportiva e Culturale “Melina” relativa al Giuoco del
					Frisbee (Melina svolge le sue attività dal 2003 presso gli impianti
					sportivi di Limena). In particolare la P.U.F. si occupa di diffondere
					la conoscenza e la pratica di un nuovo sport emergente in Italia,
					l'ULTIMATE FRISBEE e delle altre discipline sportive praticate con il
					frisbee. Le finalità dell'Associazione sono: <br />
					<br />
					<b>{"a)"}</b> promuovere la diffusione del FLYING DISC (frisbee) come
					attività sportiva e come gioco, e quindi riunire, coordinare e
					agevolare lo sviluppo di associazioni, clubs, enti, costituiti per lo
					scopo della diffusione e della conoscenza delle attività sportive del
					disco (FLYING DISC). <br />
					<br />
					<b>{"b)"}</b> incoraggiare e salvaguardare lo “SPIRITO DEL GIOCO”,
					principio di base per ogni giocatore di FLYING DISC nel mondo, secondo
					il quale “in qualità di compagni piuttosto che di avversari si compete
					solo contro i limiti delle proprie possibilità tecniche piuttosto che
					gli uni contro gli altri, dove in un clima di pace la pratica del
					FLYING DISC possa essere apprezzato per la sua capacità di riunire la
					gente secondo il detto per cui giocando insieme si impara a vivere
					insieme” - (tratto dallo statuto della W.F.D.F.). La voglia di giocare
					ed il fairplay devono prevalere sulla voglia di vincere a tutti i
					costi con ogni mezzo anche sulla “pelle” dell'avversario e devono
					mantenere sempre le caratteristiche originali del FLYING DISC evitando
					quindi la presenza di arbitri per regolare la disciplina in campo.
					<br />
					<br />
					<b>{"c)"} </b> studiare e realizzare ogni attività tendente ad una
					miglior conoscenza e diffusione del FLYING DISC, quali manifestazioni,
					dimostrazioni, seminari, corsi didattici e di formazione di tecnici
					federali competenti nella disciplina del disco, ed ogni altra
					iniziativa atta a favorire tale sviluppo. L'Associazione P.U.F. è un
					organo riconosciuto e affiliato a:
					<br />
					<br /> - Federazione Italiana Flying Disc (FIFD)
					<br /> - European Flying Disc Federation (EFDF)
					<br /> - World Flying Disc Federation (WFDF)
					<br /> - U.I.S.P. Padova
				</p>
			</div>
		</div>
	);
};
